import {
  BasketPageSources,
  BasketRateAttributes,
  DiscoverListsId,
  EditPaymentSources,
  EventSources,
  LikeProductSource,
  NonSignInPopupSources,
  OrderStatuses,
  PaymentSource,
  PayoutMethods,
  ProductPageSources,
  ShippingPriceOptions,
} from 'types';
import { AnalyticsSrv } from './AnalyticsSrv';

const userLoggedInThreeDays = () => AnalyticsSrv.track('three_days_user');

const postImpression = (id: string) => AnalyticsSrv.track('post_impression', { postId: id });

const createPost = (isRepost: boolean, totalMentions: number) =>
  AnalyticsSrv.track('post_created', { is_repost: isRepost, mentionsCount: totalMentions });

const editPost = () => AnalyticsSrv.track('post-edited');

const deletePost = () => AnalyticsSrv.track('deleted_post');

const deleteMojo = (id: string) => AnalyticsSrv.track('delete_mojo', { mojo_id: id });

const hidePost = () => AnalyticsSrv.track('hide_post');

const hidePostFromActivity = () => AnalyticsSrv.track('hide_from_activity');

const reportPost = () => AnalyticsSrv.track('report_post');

const likePost = () => AnalyticsSrv.track('liked_post');

const unlikePost = () => AnalyticsSrv.track('disliked_post');

const repost = () => AnalyticsSrv.track('repost');

const undoRepost = () => AnalyticsSrv.track('undo_repost');

const signUpSendSms = () => AnalyticsSrv.track('signup_sms_sent');

const enterBusinessPage = () => AnalyticsSrv.track('entered_add_business_page');

const completeSignUp = (provider: 'facebook' | 'google' | 'tedooo') =>
  AnalyticsSrv.track('signup_complete', { provider });

const sendMessage = (group: 'true' | 'false') =>
  AnalyticsSrv.track('message_sent', { group, forward: 'false' });

const startSignUp = (provider: 'facebook' | 'google' | 'tedooo') =>
  AnalyticsSrv.track('signup_started', { provider });

const createShopStarted = (from_onboarding: boolean) =>
  AnalyticsSrv.track('addshop_lets_start', { from_onboarding });

const createShopFinished = (
  from_onboarding: boolean,
  manualPayments: string[],
  isEdit: boolean,
  fromDraft: boolean,
) => AnalyticsSrv.track('addshop_complete', { from_onboarding, manualPayments, isEdit, fromDraft });

const createShopExit = (
  step: string,
  isEdit: boolean,
  action: 'save draft' | 'discard changes' | 'delete draft' | 'cancel editing',
) => AnalyticsSrv.track('addshop_exit', { step, isEdit, action });

const deleteAccount = () => AnalyticsSrv.track('delete_account');

const changePassword = () => AnalyticsSrv.track('change_password');

const updateCurrency = () => AnalyticsSrv.track('update_currency');

const onboardingInterest = (count: number) =>
  AnalyticsSrv.track('onboarding_interest_all', { count });

const onboardingStarted = (isSeller: boolean) =>
  AnalyticsSrv.track('onboarding_started', { isSeller });

const onboardingGroupSuggestions = (groups: string[]) => {
  const selectedGroups = groups.join('|');

  return AnalyticsSrv.track('onboarding_group_suggestions', { selectedGroups });
};

const onboardingShopSuggestions = (shops: string[]) => {
  const selectedShops = shops.join('|');

  return AnalyticsSrv.track('onboarding_shop_suggestions', { selectedShops });
};

const onboardingFinished = () => AnalyticsSrv.track('onboarding_finished');

const onComment = (totalMentions: number) =>
  AnalyticsSrv.track('create_comment', { mentionsCount: totalMentions });

const onReply = (totalMentions: number) =>
  AnalyticsSrv.track('create_reply', { mentionsCount: totalMentions });

const onOrderStart = (orderId: string, source: 'buy this item' | 'basket') =>
  AnalyticsSrv.track('order_started', { orderId, source });

const onOrderStatusChange = (status: OrderStatuses, orderId: string) =>
  AnalyticsSrv.track('order_status_changed', { status, orderId });

const onOrderEnd = (orderId: string, reason: 'declined' | 'cancelled' | 'completed') =>
  AnalyticsSrv.track('order_ended', { orderId, reason });

const onOrderShare = (orderId: string, source: 'tedooo' | 'whatsapp' | 'facebook' | 'share') =>
  AnalyticsSrv.track('share_order', { orderId, source });

const onConnectPayout = (provider: PayoutMethods, source: EditPaymentSources) =>
  AnalyticsSrv.track('payout_connected', { source, provider });

const onDisconnectPayout = (provider: PayoutMethods) =>
  AnalyticsSrv.track('payout_disconnected', { provider });

const onTransactionSuccess = (
  id: string,
  provider: PayoutMethods,
  formattedPrice: string,
  description: string,
) => {
  AnalyticsSrv.track('send_transaction_success', {
    provider,
    price: formattedPrice,
    description,
    transaction_id: id,
  });
};

const onOrderPaymentComplete = (
  source: PaymentSource,
  provider: PayoutMethods,
  price: string,
  isGuest: boolean,
  attribute?: BasketRateAttributes,
  checkoutId?: string,
  orderId?: string,
  visionOfferId?: string,
) => {
  let shipping_rate_attribute;
  if (attribute === BasketRateAttributes.BestValue) {
    shipping_rate_attribute = 'best_value';
  } else if (attribute === BasketRateAttributes.Cheapest) {
    shipping_rate_attribute = 'cheapest';
  } else if (attribute === BasketRateAttributes.Fastest) {
    shipping_rate_attribute = 'fastest';
  }
  AnalyticsSrv.track('order_payment_complete', {
    source,
    provider,
    price,
    shipping_rate_attribute,
    checkout_id: checkoutId,
    is_guest: isGuest,
    order_id: orderId,
    vision_offer_id: visionOfferId,
  });
};

const onOrderPaymentFailed = (provider: 'stripe') =>
  AnalyticsSrv.track('payment_failed', { provider });

const onPaypalConnectionFail = (reason: 'cannot receive payments' | 'verify email' | 'other') =>
  AnalyticsSrv.track('paypal_connection_failed', { reason });

const onAddAnotherPayoutFromPopup = (missingPayout: PayoutMethods) =>
  AnalyticsSrv.track('click_another_payout', { missingPayout });

const onUpdateManualPayments = (providers: string[]) =>
  AnalyticsSrv.track('manual_payments_updated', { providers });

const onConnectShippo = () => AnalyticsSrv.track('shippo_connect');

const onSkipShippo = () => AnalyticsSrv.track('shippo_skip_connection');

const onDeactivateShippo = () => AnalyticsSrv.track('shippo_deactivate');

const onDeleteShippingProfile = () => AnalyticsSrv.track('shipping_profile_deleted');

const onEditShippingProfile = (
  shippingPriceOption: ShippingPriceOptions,
  isShippingToSpecificCountries: boolean,
  source: string,
) =>
  AnalyticsSrv.track('shipping_profile_edited', {
    shippingPriceOption,
    isShippingToSpecificCountries,
    source,
  });

const onAddShippingProfile = (
  shippingPriceOption: ShippingPriceOptions,
  isShippingToSpecificCountries: boolean,
  source: string,
) =>
  AnalyticsSrv.track('shipping_profile_created', {
    shippingPriceOption,
    isShippingToSpecificCountries,
    source,
  });

const onDuplicateShippingProfile = () => AnalyticsSrv.track('shipping_profile_duplicate');

const onShippingProfileCancel = () => AnalyticsSrv.track('shipping_profile_cancelled');

const onSkipCreateShippingLabel = () => AnalyticsSrv.track('ship_order_without_label');

const onSendShippingLabelToEmail = () => AnalyticsSrv.track('send_shipping_files_to_email');

const onDownloadPackageSlip = () => AnalyticsSrv.track('download_packing_slip');

const onDownloadShippingLabel = () => AnalyticsSrv.track('download_shipping_label');

const onShippingLabelCancel = () => AnalyticsSrv.track('shipping_label_cancelled');

const onViewedPremiumScreen = () => AnalyticsSrv.track('viewed_premium_screen');

const onClosedPremiumScreen = () => AnalyticsSrv.track('closed_premium_screen');

const onCreateShippingLabel = (
  packageId: string,
  isAddressEdited: boolean,
  hasInsurance: boolean,
  hasSignature: boolean,
  orderId: string,
) => {
  const shippingLabelInfo = {
    package: packageId,
    isAddressEdited,
    withInsurance: hasInsurance,
    withSignature: hasSignature,
    orderId,
  };

  return AnalyticsSrv.track('shipping_label_created', { shippingLabelInfo });
};

const onDraftClick = () => AnalyticsSrv.track('shop_draft_clicked');

const onEnterShopSavedItemsPage = () => AnalyticsSrv.track('enter_shop_items_page');

const onEnterFavoritesPage = (source: 'toast' | 'more' | 'discover') =>
  AnalyticsSrv.track('enter_favorites_page', { source });

const onLikeProduct = (source: LikeProductSource, listId?: DiscoverListsId) =>
  AnalyticsSrv.track('unlike_product', { source, listId });

const onUnLikeProduct = () => AnalyticsSrv.track('unlike_product');

const onExploreClick = () => AnalyticsSrv.track('favorites_explore_click');

const onDiscoverSectionClick = () => AnalyticsSrv.track('click_discover_section');

const onSubCategoryClick = (category: string, subCategoryName: string) =>
  AnalyticsSrv.track('click_sub_category', { subCategoryName, category });

const onCategoryClick = (name: string, source: string) =>
  AnalyticsSrv.track('click_category', { name, source });

const onProductsPageClick = (source: string, listId: DiscoverListsId) =>
  AnalyticsSrv.track('enter_products_page', { source, listId });

const onCategoriesPageClick = () =>
  AnalyticsSrv.track('enter_categories_page', { source: 'discover' });

const onDiscoverPageScroll = () => AnalyticsSrv.track('discover_user_scrolled');

const onMojoProductClick = (
  mojoId: string,
  sessionId: string,
  source: EventSources,
  originSource?: EventSources,
) =>
  AnalyticsSrv.track('click_on_linked_item', {
    mojo_session_id: sessionId,
    mojo_id: mojoId,
    source,
    origin_source: originSource,
  });

const onOpenByMail = (source: string, mailId: string | null) => {
  AnalyticsSrv.track('app_open_by_mail', { source, mail_id: mailId });
};

const onMojoSessionStart = (sessionId: string, source: EventSources) =>
  AnalyticsSrv.track('mojo_session_started', {
    mojo_session_id: sessionId,
    source,
    source_type: 'specific',
  });

const onMojoSessionEnded = (
  sessionId: string,
  source: EventSources,
  duration: number,
  originSourceType?: 'specific',
) =>
  AnalyticsSrv.track('mojo_session_finished', {
    mojo_session_id: sessionId,
    source,
    source_type: originSourceType,
    ms_duration: duration,
  });

const onEnterMojo = (mojoId: string, source: EventSources) =>
  AnalyticsSrv.track('enter_mojo', { mojo_id: mojoId, source, source_type: 'specific' });

const onMojoImpression = (mojoId: string) =>
  AnalyticsSrv.track('mojo_impression', { mojo_id: mojoId });

const onMojoEnd = (
  mojoId: string,
  source: EventSources,
  duration: number,
  percentage: number,
  sessionId: string,
  originSourceType?: 'specific',
) =>
  AnalyticsSrv.track('mojo_completion', {
    mojo_id: mojoId,
    source,
    source_type: originSourceType,
    duration_ms: duration,
    percentage,
    mojo_session_id: sessionId,
  });

const onMojoLike = (
  mojoId: string,
  source: EventSources,
  sessionId?: string,
  originSource?: string,
  originSourceType?: 'specific',
) =>
  AnalyticsSrv.track('like_mojo', {
    mojo_id: mojoId,
    source,
    source_type: originSourceType,
    mojo_session_id: sessionId,
    origin_source: originSource,
  });

const onViewProfileFromMojo = (
  mojoId: string,
  source: EventSources,
  originSource: EventSources | null,
  mojoSessionId: string,
  originSourceType?: 'specific',
) =>
  AnalyticsSrv.track('click_profile_from_mojo', {
    mojo_id: mojoId,
    source,
    source_type: originSourceType,
    origin_source: originSource,
    mojo_session_id: mojoSessionId,
  });

const onShareMojo = (
  mojoId: string,
  source: EventSources,
  originSource: EventSources | null,
  sessionId: string,
  originSourceType?: 'specific',
) =>
  AnalyticsSrv.track('share_mojo', {
    mojo_id: mojoId,
    source,
    source_type: originSourceType,
    origin_source: originSource,
    mojo_session_id: sessionId,
  });

const onMojoFollowUser = (
  mojoId: string,
  source: EventSources,
  originSource: EventSources | null,
  sessionId: string,
  originSourceType?: 'specific',
) =>
  AnalyticsSrv.track('follow_user_from_mojo', {
    mojo_id: mojoId,
    source,
    origin_source: originSource,
    mojo_session_id: sessionId,
    source_type: originSourceType,
  });

const onVisionGenerate = (usedTags: boolean, generateId: string, hasImage: boolean) =>
  AnalyticsSrv.track('generate_vision_finished', {
    used_smart_tags: usedTags,
    generate_id: generateId,
    has_image: hasImage,
  });

const onEnterCreditsPage = (reason: 'no_credit_left' | 'banner') =>
  AnalyticsSrv.track('enter_credit', {
    reason,
  });

const onClickRequestToBuyVision = (isOwner: boolean, visionId: string) =>
  AnalyticsSrv.track('click_vision_request_to_buy', {
    vision_owner: isOwner,
    vision_id: visionId,
  });

const onSubmitVisionRequest = (isOwner: boolean, visionId: string) =>
  AnalyticsSrv.track('vision_request_created', {
    vision_owner: isOwner,
    vision_id: visionId,
  });

const onRegenerateVision = () => AnalyticsSrv.track('regenerate_vision');

const onSaveVision = (isOwner: boolean, visionId) =>
  AnalyticsSrv.track('save_vision', { owner: isOwner, id: visionId });

const onEnterVisionPreview = (
  source: 'discover' | 'visions' | 'my_visions' | 'generated_visions' | 'link' | 'feed',
  visionId,
) => AnalyticsSrv.track('enter_vision_preview', { source, vision_id: visionId });

const onEnterVisionsPage = (
  source: 'link' | 'more' | 'guide' | 'notifications' | 'create_visions',
) => AnalyticsSrv.track('enter_visions', { source });

const onClickViewOffer = (offerId: string, isBestMatch: boolean) =>
  AnalyticsSrv.track('enter_vision_offer_checkout', {
    best_match: isBestMatch,
    offer_id: offerId,
  });

const onDeleteVisionRequest = (requestId: string) =>
  AnalyticsSrv.track('vision_request_deleted', { request_id: requestId });

const onDeclineVisionOffer = (offerId: string, reason: string) =>
  AnalyticsSrv.track('decline_vision_offer', { offer_id: offerId, reason });

const onClickSendVisionOffer = (requestId: string) =>
  AnalyticsSrv.track('enter_create_vision_offer', { request_id: requestId });

const onSubmitVisionOffer = (requestId: string, offerId: string) =>
  AnalyticsSrv.track('vision_offer_created', { request_id: requestId, offer_id: offerId });

const onVisionPaymentSuccess = (offerId: string) =>
  AnalyticsSrv.track('vision_payment_success', { offer_id: offerId });

const onHideVisionRequest = (requestId: string) =>
  AnalyticsSrv.track('hide_vision_request', { request_id: requestId });

const onDeleteVisionOffer = (offerId: string) =>
  AnalyticsSrv.track('vision_offer_deleted', { offer_id: offerId });

const onEnterVisionRequestPage = (requestId: string) =>
  AnalyticsSrv.track('enter_vision_request_offers', { request_id: requestId });

const onRemoveFromBasket = (cartItemId: string, status: 'available' | 'unavailable' | 'sold_out') =>
  AnalyticsSrv.track('remove_from_basket', { cart_item_id: cartItemId, status });

const onCheckoutFromShopClick = (checkoutId: string, source: BasketPageSources) =>
  AnalyticsSrv.track('click_checkout_from_shop', { checkout_id: checkoutId, source });

const onEnterBasketPage = (source: BasketPageSources) =>
  AnalyticsSrv.track('enter_basket_page', { source });

const onBuyThisItemClick = (
  stockLeft: number | null,
  actionButton: 'paypal' | 'buy_now',
  isGuest: boolean,
  displayedBannerId?: string,
) =>
  AnalyticsSrv.track('buy_this_item_clicked', {
    displayed_banner: displayedBannerId,
    stock_left: stockLeft || undefined,
    action_button: actionButton,
    is_guest: isGuest,
  });

const onAddToBasketClick = (
  source: ProductPageSources,
  stockLeft: number | null,
  productId: string,
  displayedBannerId?: string,
) =>
  AnalyticsSrv.track('add_to_basket_clicked', {
    source,
    displayed_banner: displayedBannerId,
    stock_left: stockLeft || undefined,
    product_id: productId,
  });

const onOpenSignupPopup = (source: NonSignInPopupSources) =>
  AnalyticsSrv.track('open_signup_popup', { source });

export const AnalyticsEvents = {
  postImpression,
  reportPost,
  createPost,
  editPost,
  deletePost,
  deleteMojo,
  signUpSendSms,
  hidePost,
  hidePostFromActivity,
  likePost,
  startSignUp,
  unlikePost,
  completeSignUp,
  userLoggedInThreeDays,
  enterBusinessPage,
  sendMessage,
  createShopStarted,
  createShopFinished,
  createShopExit,
  deleteAccount,
  changePassword,
  updateCurrency,
  undoRepost,
  repost,
  onboardingInterest,
  onboardingStarted,
  onboardingGroupSuggestions,
  onboardingShopSuggestions,
  onboardingFinished,
  onComment,
  onReply,
  onOrderStart,
  onOrderStatusChange,
  onOrderEnd,
  onBuyThisItemClick,
  onOrderShare,
  onConnectPayout,
  onDisconnectPayout,
  onOrderPaymentComplete,
  onPaypalConnectionFail,
  onAddAnotherPayoutFromPopup,
  onOrderPaymentFailed,
  onUpdateManualPayments,
  onConnectShippo,
  onSkipShippo,
  onDeactivateShippo,
  onDeleteShippingProfile,
  onEditShippingProfile,
  onAddShippingProfile,
  onDuplicateShippingProfile,
  onShippingProfileCancel,
  onSendShippingLabelToEmail,
  onDownloadPackageSlip,
  onDownloadShippingLabel,
  onSkipCreateShippingLabel,
  onCreateShippingLabel,
  onShippingLabelCancel,
  onDraftClick,
  onViewedPremiumScreen,
  onClosedPremiumScreen,
  onUnLikeProduct,
  onEnterShopSavedItemsPage,
  onExploreClick,
  onEnterFavoritesPage,
  onLikeProduct,
  onDiscoverSectionClick,
  onSubCategoryClick,
  onCategoryClick,
  onProductsPageClick,
  onDiscoverPageScroll,
  onCategoriesPageClick,
  onMojoProductClick,
  onMojoSessionStart,
  onMojoSessionEnded,
  onEnterMojo,
  onMojoImpression,
  onMojoLike,
  onMojoEnd,
  onViewProfileFromMojo,
  onShareMojo,
  onMojoFollowToggle: onMojoFollowUser,
  onVisionGenerate,
  onEnterCreditsPage,
  onClickRequestToBuyVision,
  onRegenerateVision,
  onSaveVision,
  onEnterVisionPreview,
  onEnterVisionsPage,
  onSubmitVisionRequest,
  onClickViewOffer,
  onDeleteVisionRequest,
  onDeclineVisionOffer,
  onClickSendVisionOffer,
  onSubmitVisionOffer,
  onVisionPaymentSuccess,
  onHideVisionRequest,
  onDeleteVisionOffer,
  onOpenByMail,
  onEnterVisionRequestPage,
  onRemoveFromBasket,
  onCheckoutFromShopClick,
  onEnterBasketPage,
  onTransactionSuccess,
  onAddToBasketClick,
  onOpenSignupPopup,
};

import { mixpanelHelpers } from './MixpanelHelper';
import { amplitudeHelpers } from './AmplitudeHelper';

const track = (event: string, properties?: Record<string, any>) => {
  mixpanelHelpers.track(event, properties);
  amplitudeHelpers.track(event, properties);
};

const initialize = (distinctId: string | null) => {
  amplitudeHelpers.init(distinctId);
  mixpanelHelpers.init(distinctId);
};

export const AnalyticsSrv = {
  initialize,
  track,
};

import { Provider, ReactReduxContext } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga';
import 'styles/slick/slick.css';
import 'styles/slick/slick-theme.css';
import mixpanel from 'mixpanel-browser';
import { init } from '@amplitude/analytics-browser';
import { ConfigProvider } from 'antd';

import { persist, store } from 'store/Store';
import Routes from 'routes/Routes';
import Popups from 'components/popup/Popups';
import Message from './components/message/Message';
import Drawers from './components/drawers/Drawers';
import SocketHandler from 'store/chat/socket/SocketWrapper';
import { API_KEYS } from 'constant';
import { AnalyticsSrv } from './services/AnalyticsSrv';

ReactGA.initialize(API_KEYS.GOOGLE_CLIENT_ID);

init(API_KEYS.AMPLITUDE);
mixpanel.init(API_KEYS.MIXPANEL, { persistence: 'localStorage' });

window.onload = () => AnalyticsSrv.track('app_open');
window.onunload = () => AnalyticsSrv.track('app_close');

const App = () => {
  return (
    <ConfigProvider theme={{ hashed: false }}>
      <Provider store={store} context={ReactReduxContext}>
        <PersistGate loading={null} persistor={persist}>
          <BrowserRouter>
            <SocketHandler>
              <Routes />
              <Popups />
              <Drawers />
              <Message />
            </SocketHandler>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
